<template>
  <div class="nav" v-loading="isloading">
    <div class="nav-left" @click="goBack">
      <img src="~@/assets/img/reverse_left.png" alt="" class="nav-left-img" />
      <div class="title">{{ props.title }}</div>
    </div>
    <div class="nav-right" v-if="props.CancelOrder" @click="onCancelOrder">
      {{ lang.v.cancellationOrder }}
    </div>
    <div class="nav-right" v-if="props.CancelPedding" @click="onCancelPedding">
      {{ lang.v.CancelOrder }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { fetchRemoveOrder, fetchRemovedGoods } from '../../api/fetcher';
import { lang } from '@/lang';
const router = useRouter();
const route = useRoute();
const isloading = ref(false);
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  CancelOrder: {
    type: Boolean,
    default: false,
  },
  CancelPedding: {
    type: Boolean,
    default: false,
  },
  version: {
    type: Number,
    default: 0,
  },
});
const goBack = () => {
  router.go(-1);
};

//取消订单
const onCancelOrder = async () => {
  isloading.value = true;
  await fetchRemoveOrder({
    orderId: route.query.orderId || route.query.id,
    version: props.version,
  }).then(res => {
    isloading.value = false;
    if (res?.data?.code == 0) {
      router.go(-1);
    }
  });
  isloading.value = false;
};

//取消挂单
const onCancelPedding = async () => {
  isloading.value = true;
  await fetchRemovedGoods({
    commodityId: route.query.id,
    version: props.version,
  }).then(() => {
    isloading.value = false;
    router.go(-1);
  });
  isloading.value = false;
};
</script>

<style scoped lang="less">
.nav {
  padding-bottom: 0.14rem;
  display: flex;
  align-items: center;
  border-bottom: 0.02rem solid #eaeaea;
  justify-content: space-between;
  .nav-left {
    display: flex;
    align-items: center;
    .nav-left-img {
      margin-right: 0.06rem;
      width: 0.2rem;
      height: 0.2rem;
    }
  }
  .nav-right {
    font-weight: 400;
    font-size: 0.14rem;
    color: #858c9f;
    line-height: 0.16rem;
    padding-bottom: 0.02rem;
    border-bottom: 1px dashed #858c9f;
  }
}
</style>
